import type { Row } from "@electric-sql/client";
import { useShape } from "./useShape";
import type { TrashItem } from "#build/interfaces/trashItem";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/vue-query";

function getGlobalPictures(data: Row[]): TrashItem[] {
  if (!data) return []

  return data.sort((a: Row, b: Row) => {
    return new Date(b.ts as string).getTime() - new Date(a.ts as string).getTime()
  }) as unknown as TrashItem[]
}

export default function useGlobalPics() {
  const queryClient = useQueryClient()
  const config = useRuntimeConfig()
  const pageSize = 15

  const urlPath = config.public.ELECTRIC_SQL_URL + 'v1/shape'
  const options = {
    url: urlPath,
    table: 'trashitempb',
  }

  const shapeData = useShape(options);

  const {
    data: picsTakenData,
    isLoading: isLoadingPicsTaken
  } = useQuery({
    queryKey: ['globalPicsTaken'],
    queryFn: () => {
      return Promise.resolve(
        getGlobalPictures(shapeData.value?.data).length
      )
    },
    enabled: !!shapeData.value,
  })

  const picsTaken = computed(() => picsTakenData.value || 0)

  const {
    data: picturesData,
    isLoading: isLoadingPictures,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['globalPictures'],
    queryFn: ({ pageParam }) => {
      const start = pageParam * pageSize;
      const end = start + pageSize

      return Promise.resolve(
        getGlobalPictures(shapeData.value?.data).slice(start, end)
      )
    },
    getNextPageParam: (_, allPages) => {
      const totalItems = picsTaken.value
      const loadedItems = allPages.flat().length

      if (loadedItems >= totalItems) {
        return undefined
      }
      return allPages.length
    },
    initialPageParam: 0,
  })

  watch(
    () => shapeData.value?.data,
    () => {
      queryClient.invalidateQueries({ queryKey: ['globalPicsTaken'] })
      queryClient.invalidateQueries({ queryKey: ['globalPictures'] })
    }
  )

  const pictures = computed<TrashItem[]>(() => picturesData.value?.pages.flat() || [])
  const isPending = computed(() => isLoadingPicsTaken.value || isLoadingPictures.value)

  return {
    picsTaken,
    pictures,
    isPending,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  }
}
